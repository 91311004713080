var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("prepend")], 2), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("center")], 2), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_vm._t("append")], 2), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }